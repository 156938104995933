<template>
  <div class="layer-page-div">
    <div class="big-img-div" v-show="isShowBigImg" @click="onHideBigImgDiv">
      <img ref="bigImg" src="" alt=""/>
    </div>
    <div class="layer-content-div">
      <div class="item-div">
        <label>昵称：{{partsInfo.nickname}}</label>
      </div>
      <div class="item-div">
        <label>发布时间：{{partsInfo.createTime}}</label>
      </div>
      <div class="item-div">
        <label>描述</label>
        <div class="content-div">{{partsInfo.desc}}</div>
      </div>
      <div class="item-div">
        <label>配件图片</label>
        <div class="img-div">
          <img v-for="item in partsInfo.img" :src="`${$imgBaseURL}/${item}`" alt="" @click="onShowBigImgDiv($event)">
        </div>
      </div>
      <div class="operate-div text-c">
        <input type="button" value="删除" @click="onDel">
      </div>
    </div>
  </div>
</template>

<script>
import { getOldPartsDetails, postOldPartsDel } from "../../common/api";

export default {
  props: {
    layerid: {
      type: String,
      default: ""
    },
    partsId: {
      type: String,
      default: ""
    },
    refreshData: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      partsInfo: {},
      isShowBigImg: false
    }
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      getOldPartsDetails({ id: this.partsId })
        .then(data => {
          if(!Array.isArray(data.img)){
            data.img = [];
          }
          this.partsInfo = data;
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("查询旧件信息失败");
          }
          this.$layer.close(this.layerid);
        });
    },
    onShowBigImgDiv(event) {
      this.$refs.bigImg.src = event.target.src;
      this.isShowBigImg = true;
    },
    onHideBigImgDiv() {
      this.isShowBigImg = false;
    },
    onDel() {
      let _this = this;
      this.$layer.confirm(`是否删除该旧件？`, layerid => {
        postOldPartsDel({ id: this.partsId })
          .then(() => {
            this.$layer.msg("操作成功");
            this.refreshData();
            this.$layer.close(layerid);
            this.$layer.close(_this.layerid);
          })
          .catch(error => {
            this.$layer.close(layerid);
            this.$layer.close(_this.layerid);
            if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
              this.$layer.msg(error.data.msg);
            } else {
              this.$layer.msg("操作失败");
            }
          });
      });
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .big-img-div {
    position: absolute;
    width: 100%;
    height: calc(100% - 42px);
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      align-items: center;
    }
  }
  .layer-content-div {
    width: 80%;
    margin: 10px auto 0 auto;
    .item-div {
      margin-bottom: 10px;
      .content-div {
        width: calc(100% - 8px);
        height: 100px;
        padding: 3px;
        border: 1px solid #cccccc;
        border-radius: 3px;
        background-color: #e6e6e6;
      }
      .img-div {
        width: 100%;
        height: 100px;
        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
          margin-left: 5px;
        }
        img:first-child {
          margin-left: 0;
        }
      }
      textarea {
        width: calc(100% - 8px);
        height: 100px;
        resize: none;
        padding: 3px;
      }
    }
    .operate-div {
      margin-top: 20px;
      input {
        width: 120px;
        height: 35px;
        color: white;
        font-size: 16px;
        border: none;
        background: #4676c8;
        margin-top: 10px;
      }
      .refuse-input {
        margin-left: 5px;
      }
      .tips-div {
        width: 100%;
        height: 25px;
        margin-top: 10px;
      }
    }
  }
}
</style>